import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "../home/home.scss";
const Video = () => {

    const initial = {
        opacity: 0,
        y: 15,
        transition: { duration: 0.5 },
    };

    const animate = {
        opacity: 1,
        y: 0,
        transition: { duration: 0.5 },
    };
    return (
        <>
            <div className="video-hero">
                <div className="contain-wrapper">
                    <div
                        className="contain"
                        style={{
                            padding: "56.25% 0 0 0",
                            position: "relative",
                        }}
                    >
                        <Link
                            style={{
                                position: "absolute",
                                top: 50,
                                left: "calc(50% - 100px)",
                                width: "200px",
                            }}
                            className="logo-link"
                            to="/"
                        >
                            <img
                                src="../images/logo.svg"
                                className="logo"
                                alt="logo"
                            />
                        </Link>
                        <iframe
                            src="https://player.vimeo.com/video/986463364?h=061c10df2b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                            title="Rosebery CGI animation"
                        ></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
                <div className="footer-hero two">
                    <div className="footer-pattern">
                        <div className="footer-top">
                            <h2>
                                150,000 sq ft
                                <br />
                                <span>sustainable &amp; innovative</span>
                                <br />
                                office space.
                            </h2>
                            <h2>#workbetter</h2>
                        </div>
                        <div className="footer-bot">
                            <h3>DISCOVER MORE</h3>
                            <div className="agents-wrapper">
                                <motion.div
                                    initial={initial}
                                    whileInView={animate}
                                    viewport={{ once: true }}
                                    className="agent"
                                >
                                    <div className="img-wrapper">
                                        <img
                                            src="../images/jll.svg"
                                            alt="jll"
                                        />
                                    </div>
                                    <div className="details">
                                        <p>Cameron Stott</p>
                                        <p>+44(0) 7785 907 096</p>
                                        <a href="mailto:cameron.stott@jll.com,james.thomson@cushwake.com">
                                            <p>cameron.stott@jll.com</p>
                                        </a>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={initial}
                                    whileInView={animate}
                                    viewport={{ once: true }}
                                    className="agent"
                                >
                                    <div className="img-wrapper">
                                        <img
                                            src="../images/cush.svg"
                                            alt="cush"
                                        />
                                    </div>
                                    <div className="details">
                                        <p>James Thomson</p>
                                        <p>+44(0) 7768 472 130</p>
                                        <a href="mailto:cameron.stott@jll.com,james.thomson@cushwake.com">
                                            <p>james.thomson@cushwake.com</p>
                                        </a>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="wrapper">
                    <p>A development by</p>
                    <img src="../images/aviva.svg" alt="aviva" />
                </div>
            </div>
        </>
    );
};
export default Video;
