import { useEffect, useLayoutEffect, useState } from "react";
import "../../scss/global.scss";
import "./home.scss";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade"; // Import the fade effect CSS

// Import Swiper modules
import { Autoplay, EffectFade } from "swiper/modules";

import Icon from "../../components/icon";
import BetterThings from "../../components/betterthings";
import Come from "../../components/come";
import { Link } from "react-router-dom";

const Home = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const middleCopyTrans = {
        duration: 1,
        delay: 6,
    };

    const initial = {
        opacity: 0,
        y: 15,
        transition: { duration: 0.5 },
    };

    const animate = {
        opacity: 1,
        y: 0,
        transition: { duration: 0.5 },
    };

    const [videoSource, setVideoSource] = useState(
        window.innerWidth <= 768 ? "../videos/1_mb.mp4" : "../videos/1.mp4"
    );

    useEffect(() => {
        const checkIsMobile = () => {
            if (window.innerWidth <= 768) {
                setVideoSource("../videos/1_mb.mp4");
            } else {
                setVideoSource("../videos/1.mp4");
            }
        };
        checkIsMobile();
        window.addEventListener("resize", checkIsMobile);
        return () => {
            window.removeEventListener("resize", checkIsMobile);
        };
    }, []);

    useEffect(() => {
        const handleAnimationComplete = () => {
            setTimeout(() => {
                const introAnimElement = document.getElementById("intro-anim");
                const introGalleryElement =
                    document.getElementById("swiper-holder");
                if (introAnimElement) {
                    introAnimElement.classList.add("hideintro");
                    introAnimElement.classList.remove("hideswiper");
                }
            }, 9000); // 9 seconds delay
        };

        handleAnimationComplete();
        return () => {
            // Cleanup function if necessary
        };
    }, []);

    return (
        <>
            <div className="swiper-holder" id="swiper-holder">
                {/* Swiper Component */}
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{
                        delay: 2800,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: false,
                    }}
                    loop={true} // Enable infinite looping
                    effect="fade" // Set the fade effect
                    modules={[Autoplay, EffectFade]} // Import necessary modules
                    style={{ width: "100%" }} // Ensures Swiper takes full width
                >
                    <SwiperSlide>
                        <div className="holdingslide slide1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="holdingslide slide2" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="holdingslide slide3" />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="body-hero">
                <Link style={{position: 'absolute', top: 50, width: '200px'}} to="/" className="logo-link">
                    <motion.img
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 3 }}
                        src="../images/logo.svg"
                        className="logo"
                        alt="logo"
                    />
                </Link>
                <Icon />
                <div className="intro-anim" id="intro-anim">
                    <video autoPlay muted>
                        <source src={videoSource} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div>
                        <div style={{ overflowY: "hidden" }}>
                            <BetterThings />
                        </div>
                        <div style={{ overflowY: "hidden" }}>
                            <Come />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-hero">
                <div className="footer-pattern">
                    <div className="footer-top">
                        <h2>
                            150,000 sq ft
                            <br />
                            <span>sustainable &amp; innovative</span>
                            <br />
                            office space.
                        </h2>
                        <h2>#workbetter</h2>
                    </div>
                    <div className="footer-bot">
                        <h3>DISCOVER MORE</h3>
                        <div className="agents-wrapper">
                            <motion.div
                                initial={initial}
                                whileInView={animate}
                                viewport={{ once: true }}
                                className="agent"
                            >
                                <div className="img-wrapper">
                                    <img src="../images/jll.svg" alt="jll" />
                                </div>
                                <div className="details">
                                    <p>Cameron Stott</p>
                                    <p>+44(0) 7785 907 096</p>
                                    <a href="mailto:cameron.stott@jll.com,james.thomson@cushwake.com">
                                        <p>cameron.stott@jll.com</p>
                                    </a>
                                </div>
                            </motion.div>
                            <motion.div
                                initial={initial}
                                whileInView={animate}
                                viewport={{ once: true }}
                                className="agent"
                            >
                                <div className="img-wrapper">
                                    <img src="../images/cush.svg" alt="cush" />
                                </div>
                                <div className="details">
                                    <p>James Thomson</p>
                                    <p>+44(0) 7768 472 130</p>
                                    <a href="mailto:cameron.stott@jll.com,james.thomson@cushwake.com">
                                        <p>james.thomson@cushwake.com</p>
                                    </a>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="wrapper">
                    <p>A development by</p>
                    <img src="../images/aviva.svg" alt="aviva" />
                </div>
            </div>
        </>
    );
};

export default Home;
